import "core-js/modules/es.array.push.js";
import { Menu } from '@element-plus/icons-vue';
export default {
  data() {
    return {
      ui_events: {
        mobileMenu: {
          active: false
        }
      },
      ui_data: {
        menu: [{
          name: 'Главная',
          action: async () => {
            await this.$router.push('/');
          }
        }, {
          name: 'Жалобы',
          action: this.routerNewTicket
        }, {
          name: 'Предложения',
          action: this.routerNewTicket
        }, {
          name: 'Сотрудникам',
          action: async () => {
            await this.$router.push('/auth');
          }
        }]
      }
    };
  },
  methods: {
    async routerNewTicket() {
      await this.$router.push('/');
      document.querySelector('.sc-secondsection').scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
  mounted() {},
  components: {
    Menu
  },
  computed: {
    userData() {
      return this.$store.getters.USER_DATA;
    }
  },
  watch: {
    userData: {
      deep: true,
      handler() {
        if (this.userData) {
          this.ui_data.menu[3].name = this.userData.fullname.length > 0 ? this.userData.fullname : this.userData.login;
        }
      }
    }
  }
};