import SenderMain from '@/components/Sender/SenderMain.vue';
export default {
  name: 'HomeView',
  components: {
    SenderMain
  },
  methods: {
    newTicket() {
      document.querySelector('.sc-secondsection').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};