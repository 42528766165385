import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_header_main = _resolveComponent("header-main");
  const _component_router_view = _resolveComponent("router-view");
  const _component_footer_main = _resolveComponent("footer-main");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_header_main), _createVNode(_component_el_scrollbar, {
    class: "main-scrollbar"
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view), _createVNode(_component_footer_main)]),
    _: 1
  })], 64);
}