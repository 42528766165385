import pharmacies from '../../assets/docs/pharmacies';
import { ElMessage } from 'element-plus';
// import { UploadFilled } from '@element-plus/icons-vue'
export default {
  data() {
    return {
      ui_ticket: {
        type: 'Предложение',
        customer: {
          firstname: '',
          lastname: '',
          phone: ''
        },
        pharmacy: {
          forPharmacy: false,
          value: null
        },
        partnership: {
          type: null
        },
        title: '',
        content: ''
      },
      fileList: [],
      ui_ticket_sending: false,
      pharmacies,
      labelPosition: 'left',
      horListSize: 'large',
      /** @type {import('element-plus').FormRules} */
      rules: {
        customer: [{
          validator: (rule, value, callback) => {
            if (value.firstname == '') {
              callback(new Error('Пожалуйста, введите Ваше имя'));
              return;
            }
            callback();
          },
          trigger: 'blur'
        }],
        phone: [{
          validator: this.validator_phone,
          trigger: 'blur'
        }],
        pharmacy: [{
          validator: this.validator_pharmacy
        }],
        title: [{
          required: true,
          message: 'Укажите заголовок'
        }],
        content: [{
          required: true,
          message: 'Состав тикета пустой'
        }]
      }
    };
  },
  // components: {UploadFilled},
  mounted() {
    if (localStorage.getItem('sugcom-saved')) {
      try {
        let savedUi = JSON.parse(localStorage.getItem('sugcom-saved'));
        savedUi?.content ? this.ui_ticket.content = savedUi?.content : false;
        savedUi?.title ? this.ui_ticket.title = savedUi?.title : false;
        savedUi?.type ? this.ui_ticket.type = savedUi?.type : false;
        savedUi?.pharmacy?.forPharmacy ? this.ui_ticket.pharmacy.forPharmacy = savedUi?.pharmacy?.forPharmacy : false;
        savedUi?.pharmacy?.value ? this.ui_ticket.pharmacy.value = savedUi?.pharmacy?.value : false;
        savedUi?.customer?.firstname ? this.ui_ticket.customer.firstname = savedUi?.customer?.firstname : false;
        savedUi?.customer?.lastname ? this.ui_ticket.customer.lastname = savedUi?.customer?.lastname : false;
        savedUi?.customer?.phone ? this.ui_ticket.customer.phone = savedUi?.customer?.phone : false;
      } catch (e) {
        localStorage.removeItem('sugcom-saved');
      }
    }
    if (this.userData) {
      this.ui_ticket.customer.firstname = this.userData.fullname.length > 0 ? this.userData.fullname : this.userData.login;
    }
    this.windowResize();
    window.addEventListener('resize', this.windowResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.windowResize);
  },
  methods: {
    validator_phone(rule, value, callback) {
      if (this.ui_ticket.customer.phone.length < 18) {
        callback(new Error('Пожалуйста, введите Ваш номер телефона'));
        return;
      }
      callback();
    },
    validator_pharmacy(rule, value, callback) {
      if (this.ui_ticket.pharmacy.forPharmacy && this.ui_ticket.pharmacy.value == null) {
        callback(new Error('Пожалуйста, Выберите адрес аптеки из списка'));
        return;
      }
      callback();
    },
    sc_ticket_send() {
      if (this.ui_ticket_sending) return;
      this.$refs.senderform.validate(async valid => {
        if (!valid) return;
        this.ui_ticket_sending = true;
        let response = await this.$store.dispatch('serverQuery', {
          url: 'products',
          query: 'newTicket',
          data: {
            ui_ticket: JSON.stringify(this.ui_ticket)
          }
        });
        this.ui_ticket_sending = false;
        if (!response.status) {
          ElMessage.error('Не удалось отправить тикет. Проверьте интернет соединение, и затем обновите страницу.');
          return;
        }
        localStorage.removeItem('sugcom-saved');
        this.$router.replace(`ticket/${response.data.message}`);
      });
    },
    windowResize() {
      if (window.innerWidth < 760) {
        this.labelPosition = 'top';
        this.horListSize = 'default';
      } else {
        this.labelPosition = 'left';
        this.horListSize = 'large';
      }
      if (window.innerWidth < 370) {
        this.horListSize = 'small';
      }
    }
  },
  computed: {
    userData() {
      return this.$store.getters.USER_DATA;
    }
  },
  watch: {
    ui_ticket: {
      deep: true,
      handler() {
        localStorage.setItem('sugcom-saved', JSON.stringify(this.ui_ticket));
      }
    }
  }
};