export default [
    {
        address: 'Интернет Магазин rauza-ade.kz',
        time: 'Пн-Вс 9:00-21:00',
        number: '+7 (707) 501-36-86',
        region: 'Алматы'
    },
    {
        address: 'Kaspi Магазин',
        time: 'Пн-Вс 9:00-21:00',
        number: '+7 (702) 352-01-76',
        region: 'Бостандыкский район'
    },
    {
        address: 'Чайковского 22, Маметова 1, п.108',
        time: 'с 08:00-20:00 воскр вых.',
        number: '279 34 75',
        region: 'Алмалинский район'
    },
    {
        address: 'Чайковского 22, Маметова 2, пом. 100',
        time: 'с 09:00-22:00',
        number: '279 19 21',
        region: 'Алмалинский район'
    },
    {
        address: 'Чайковского 22 А',
        time: 'круглосуточно',
        number: '278 03 09, 279 86 04',
        region: 'Алмалинский район'
    },
    {
        address: 'Чайковского 13 (суббота до 14:00)',
        time: 'с 09:00-19:00 воскр вых.',
        number: '233 56 83',
        region: 'Алмалинский район'
    },
    {
        id: '8',
        address: 'Чайковского 13 Б (13/1)',
        time: 'круглосуточно',
        number: '233 57 78',
        region: 'Алмалинский район'
    },
    {
        address: 'Райымбека 150, уг. Чайковского',
        time: 'с 08:00-22:00',
        number: '233 21 60, 397 88 38',
        region: 'Алмалинский район'
    },
    {
        address: 'Толе би 284, н п 3',
        time: 'круглосуточно',
        number: '301 49 91, 301 49 85',
        region: 'Алмалинский район'
    },
    {
        id: '1',
        address: 'Райымбека, 101, ТК "Мерей" (Саяхат)',
        time: 'с 07:00-23:00',
        number: '271 12 28',
        region: 'Медеуский район'
    },
    {
        address: 'Есенова 3 (ниже Макатаева)',
        time: 'круглосуточно',
        number: '339 34 83',
        region: 'Медеуский район'
    },
    {
        address: 'Достык, 248, уг. Омарова',
        time: 'с 08:00-22:00',
        number: '339 84 99',
        region: 'Медеуский район'
    },
    {
        address: 'Достык, 296/1, н.п. 68',
        time: 'круглосуточно',
        number: '387 01 09',
        region: 'Медеуский район'
    },
    {
        address: 'Достык, 33, уг. Виноградова (Карасай батыра)',
        time: 'с 08:00-22:00',
        number: '339 84 95',
        region: 'Медеуский район'
    },
    {
        address: 'Самал 2, ул. Мендекулова, д. 105',
        time: 'круглосуточно',
        number: '225 11 45',
        region: 'Медеуский район'
    },
    {
        address: 'Манаса, д. 57, уг. Джандосова',
        time: 'с 08:00-23:00',
        number: '274 45 54',
        region: 'Медеуский район'
    },
    {
        address: 'Коктем 1, д. 19 (Весновка)',
        time: 'круглосуточно',
        number: '386 93 65',
        region: 'Медеуский район'
    },
    {
        id: '5',
        address: 'Тимирязева, д. 101, уг. Гагарина',
        time: 'круглосуточно',
        number: '274 83 89, 274 24 79',
        region: 'Бостандыкский район'
    },
    {
        id: '2',
        address: 'Гагарина 137, уг. Тимирязева',
        time: 'с 08:00-22:00',
        number: '274 81 42',
        region: 'Бостандыкский район'
    },
    {
        address: 'Гагарина 181, уг. Утепова',
        time: 'круглосуточно',
        number: '382 58 17, 382 58 22',
        region: 'Бостандыкский район'
    },
    {
        address: 'Гагарина 181, уг. Утепова (новая)',
        time: 'с 09:00-21:00',
        number: '339 34 94',
        region: 'Бостандыкский район'
    },
    {
        address: 'Розыбакиева 188, уг.Жандосова',
        time: 'с 08:00-22:00',
        number: '339 34 70',
        region: 'Бостандыкский район'
    },
    {
        address: 'Гагарина 124 (по Абая)  ЖК «Дом на Абая»',
        time: 'с 08:00-22:00',
        number: '339 34 67',
        region: 'Бостандыкский район'
    },
    {
        id: '14',
        address: 'Тимирязева, д. 107/192, уг. Радостовца',
        time: 'с 08:00-22:00',
        number: '275 47 75',
        region: 'Бостандыкский район'
    },
    {
        address: 'Орбита 4, д. 1 возле базара',
        time: 'круглосуточно',
        number: '224 11 21',
        region: 'Бостандыкский район'
    },
    {
        address: 'Орбита 4, д. 32 по ул. Биржана',
        time: 'с 08:00-23:00',
        number: '254 64 98',
        region: 'Бостандыкский район'
    },
    {
        address: 'Орбита 2, д.2 по ул. Навои',
        time: 'круглосуточно',
        number: '265 78 81',
        region: 'Бостандыкский район'
    },
    {
        address: 'Розыбакиева, д. 61, уг. Толе би',
        time: 'с 08:00-23:00',
        number: '374 68 17, 374 68 21',
        region: 'Алмалинский район'
    },
    {
        address: 'Абая Баумана д.150/230, уг. Мынбаева',
        time: 'с 08:00-24:00',
        number: '220 66 70',
        region: 'Алмалинский район'
    },
    {
        address: 'Прокофьева 148, уг. Кулымбетова (Манхеттен)',
        time: 'с 08:00-22:00',
        number: '338 25 42',
        region: 'Алмалинский район'
    },
    {
        address: 'Джамбула, д. 173, уг. Ауэзова (Айтиева)',
        time: 'с 08:00-22:00',
        number: '376 11 31',
        region: 'Алмалинский район'
    },
    {
        address: 'Джамбула, д. 211/71, уг. Айманова,пом.108',
        time: 'с 08:00-22:00',
        number: '339 54 76',
        region: 'Алмалинский район'
    },
    {
        id: '4',
        address: 'Сейфуллина 472 уг. Толе би',
        time: 'круглосуточно',
        number: '339 34 69',
        region: 'Алмалинский район'
    },
    {
        id: '6',
        address: 'Сейфуллина 472 Парафармация',
        time: 'круглосуточно',
        number: '339 34 69',
        region: 'Алмалинский район'
    },
    {
        id: '9',
        address: 'Абая Правды (5 мкр., д. 17)',
        time: 'круглосуточно',
        number: '249 34 16',
        region: 'Ауэзовский район'
    },
    {
        id: '3',
        address: 'Аксай 2, дом 73, кв. 55',
        time: 'с 08:00-22:00',
        number: '238 27 41',
        region: 'Ауэзовский район'
    },
    {
        address: 'Жетысу 4, дом 1 (Абая Момышулы)',
        time: 'круглосуточно',
        number: '230 98 51',
        region: 'Ауэзовский район'
    },
    {
        address: 'Жетысу 2, д.71 В(по Абая, м Саина Момышулы)',
        time: 'с 08:00-22:00',
        number: '339 01 63',
        region: 'Ауэзовский район'
    },
    {
        address: 'Рыскулова, 236, уг. Ахрименко СМ "Сардар"',
        time: 'с 08:30-22:30',
        number: '381 35 36',
        region: 'Алатауский район'
    },
    {
        address: 'Айнабулак 4, д.172а, по Жумабаева',
        time: 'с 08:00-22:00',
        number: '339 34 76',
        region: 'Турксибский район'
    },
    {
        address: 'Айнабулак 3, д. 90',
        time: 'с 08:00-22:00',
        number: '339 34 73',
        region: 'Турксибский район'
    },
    {
        address: 'ул. Свердлова дом 13/1',
        time: 'круглосуточно',
        number: '243 37 61',
        region: 'Турксибский район'
    },
    {
        address: 'Сейфуллина 171 (ниже Рыскулова)',
        time: 'с 08:00-22:00',
        number: '339 34 78',
        region: 'Турксибский район'
    },
    {
        address: 'Сейфуллина 18/3 уг. Чернышевского',
        time: 'с 08:00-21:00',
        number: '339 34 79',
        region: 'Турксибский район'
    },
    {
        address: 'Богдана Хмельницкого 24, уг. Орджоникидзе',
        time: 'круглосуточно',
        number: '339 34 77',
        region: 'Турксибский район'
    },
    {
        address: 'мкр. Жулдыз 1, д. 5/3, пом. 1/6',
        time: 'с 08:00-22:00',
        number: '339 01 62',
        region: 'Турксибский район'
    },
    {
        address: '(ГРЭС)Пос Утеген-Батыра, ул.Спортивная, 13б',
        time: 'с 08:00-22:00',
        number: '251 74 32',
        region: 'Область'
    },
    {
        address: 'пос. Панфилова, ул. Кульжинская, 31а,зд Смолл',
        time: 'с 08:00-22:00',
        number: '372 71 32',
        region: 'Область'
    },
    {
        address: 'Каскелен, ул. Толе би, д. 3',
        time: 'с 09:00-22:00',
        number: '8 727 371 75 11',
        region: 'Область'
    },
    {
        address: 'Есик, ул. Кешубаева, д. 41 Б',
        time: 'с 08:00-22:00',
        number: '8 72 775 404 54',
        region: 'Область'
    },
    {
        address: 'п. Бурундай, ул. Бостанова, д. 29 ТЦ "Кристалл"',
        time: 'с 08:00-21:00',
        number: '387 64 65',
        region: 'Область'
    },
    {
        address: 'Талгар,ул.Кунаева,102а(нап-в стан Сулужан)',
        time: 'с 08:00-22:00',
        number: '339 34 72',
        region: 'Область'
    },
    {
        address: 'г. Талдыкорган мкр. Молодежный д 40 кв 2 мкр. Жастар 40',
        time: 'круглосуточно',
        number: '8 (7282) 41 75 00',
        region: 'Область'
    },
    {
        id: '10',
        address: 'Тимирязева, д. 101, уг. Гагарина (новая)',
        time: 'с 08:00-22:00',
        number: '274 83 89, 274 24 79',
        region: 'Бостандыкский район'
    },
    {
        id: '11',
        address: 'Ауэзова 163 А, уг. Бухар Жырау (Жарокова)',
        time: 'с 08:00-22:00',
        number: '339 84 96',
        region: 'Алматы'
    },
    {
        id: '12',
        address: 'Толе би, 122/69',
        time: 'с 08:00-22:00',
        number: '231 29 98',
        region: 'Алматы'
    },
    {
        id: '13',
        address: 'Гоголя, 91',
        time: 'с 08:00-22:00',
        number: '339 12 35',
        region: 'Алматы'
    },
];