import { createStore } from 'vuex'
import $axios from 'axios';
import { ElMessage } from 'element-plus';

const axios = $axios.create({
    baseURL: 'https://rauza-ade.ru/api/',
    timeout: 40000,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

export default createStore({
    state: {
        id: null,
        password: null,
        userData: null,
    },
    getters: {
        USER_DATA: state => {
            return state.userData;
        },
    },
    mutations: {
        auth(ctx, payload) {
            ctx.id = payload.id;
            ctx.password = payload.password;
            ctx.userData = payload.user;
        }
    },
    actions: {
        isAuthed(ctx) {
            return ctx.state.id == null ? false : true;
        },
        serverQuery(ctx, _data) {
            let args = _data.data,
                query = _data.query,
                url = _data.url;

            if (args === undefined) args = {};
            return new Promise((resolve) => {
                var params = new URLSearchParams();
                params.append('query', query);
                params.append('account_id', ctx.state.id);
                params.append('account_password', ctx.state.password);
                for(let i in args) {
                    params.append(i, args[i]);
                }
                let x = `/${url}/`;
                if (url == '/') x = '/';
                axios.post(x, params).then(response => {
                    let data = response.data;
                    console.log(data);
                    if (data.status == 'OK') { 
                        resolve({status: true, data});
                    }
                    else {
                        resolve({status: false, data});
                    }
                }).catch(() => {
                    resolve({status: false});
                });
            });
        },
        serverFile(query, files, additional) {
			if (additional === undefined) additional = {};
			return new Promise((resolve) => {
				let sizeMB = files.size / 1024 / 1024;
				if (sizeMB > 2) {
                    ElMessage.error('Ограничение по размеру файла: 2 MB.');
					resolve(-1);
					return;
				}

				let formData = new FormData();
				formData.append('query', query);
				for(let i in additional) {
					formData.append(i, additional[i]);
				}
			
				formData.append('file', files);

				axios({
					url: `/products/`,
					method: 'POST',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					timeout: 0,
					onUploadProgress: (e) => {
						if (additional.progress) additional.progress(e);
					}
				}).then(response => {
					let data = response.data;
					console.log(data);
					if (data.status == 'OK') { 
						resolve({status: true, data: data});
					}
					else {
						for(let i in data.errors) {
                            ElMessage.error(data.errors[i]);
						}
						resolve({status: false});
					}
				});
			});
		},
    },
    modules: {
    }
})
